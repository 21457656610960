.cleared
	&::after
		content ""
		display table
		clear both

.clear
	clear both
	font-size 0

.rel
	position relative

.rel-1
	position relative
	z-index 1

.rel-2
	position relative
	z-index 2

.sup
	vertical-align super

.middle
	white-space nowrap

	&::after
		content ''
		width 0
		height 100%
		display inline-block
		vertical-align middle

.v-middle
	display inline-block
	vertical-align middle
	white-space normal

.block
	display block
	float none
	width auto

.inline-block
	display inline-block !important

.helper-last
	margin-bottom 0 !important

.helper-first
	margin-top 0 !important

.tab-content

	& > .tab-pane
		display none
		visibility hidden

	& > .active
		display block
		visibility visible

.small
	font-size 80%
	font-weight normal

.mark
	padding .2em
	background-color #fcf8e3

.text-left
	text-align left

.text-right
	text-align right

.text-center
	text-align center

.text-justify
	text-align justify !important
	word-spacing -.3ex

.text-nowrap
	white-space nowrap !important

.text-lowercase
	text-transform lowercase !important

.text-uppercase
	text-transform uppercase !important

.text-capitalize
	text-transform capitalize !important

.font-weight-normal
	font-weight normal

.font-weight-bold
	font-weight bold

.font-italic
	font-style italic

.fade
	opacity 0
	transition opacity .15s linear

	&.in
		opacity 1

.img-fluid
	display block
	max-width 100%
	height auto

.img-circle
	border-radius 50%

.img-thumbnail
	display inline-block
	max-width 100%
	height auto
	padding .25rem
	line-height 1.5
	background-color #fff
	border 1px solid #ddd
	border-radius .25rem
	transition: all .2s ease-in-out

.sr-only
	position absolute
	width 1px
	height 1px
	padding 0
	margin -1px
	overflow hidden
	clip rect(0, 0, 0, 0)
	border 0

.sr-only-focusable
	&:active
	&:focus
		position static
		width auto
		height auto
		margin 0
		overflow visible
		clip auto

.center-block
	display block
	margin-right auto
	margin-left auto

.pull-right
	float right !important

.pull-left
	float left !important

.hide
.hidden
	display none !important

.show
	display block !important

.invisible
	visibility hidden !important

.text-hide
	font 0/0 a
	color transparent
	text-shadow none
	background-color transparent
	border 0

.affix
	position fixed

.underline
	text-decoration none !important
	border-bottom-width 1px
	border-bottom-style solid

.nounderline
	text-decoration none !important

ul.dash
	list-style none

	& > li
		margin-left 1.3em

		&:before
			content '\2014\a0'
			float left
			margin-left -1.25em

.pre-scrollable
	max-height 340px
	overflow-y scroll

.pseudo-table
	display table
	width 100%
	border-collapse collapse

.pseudo-row
	display table-row

.pseudo-cell
	display table-cell
	vertical-align top

.text-truncate
	overflow hidden
	white-space nowrap
	text-overflow ellipsis
	-o-text-overflow ellipsis
	-ms-text-overflow ellipsis

.clickable
	cursor pointer

.m-x-auto
	margin-right auto !important
	margin-left auto !important

.m-a-0
	margin 0 0 !important

.m-t-0
	margin-top 0 !important

.m-r-0
	margin-right 0 !important

.m-b-0
	margin-bottom 0 !important

.m-l-0
	margin-left 0 !important

.m-x-0
	margin-right 0 !important
	margin-left 0 !important

.m-y-0
	margin-top 0 !important
	margin-bottom 0 !important

.m-a-1
	margin 1rem 1rem !important

.m-t-1
	margin-top 1rem !important

.m-r-1
	margin-right 1rem !important

.m-b-1
	margin-bottom 1rem !important

.m-l-1
	margin-left 1rem !important

.m-x-1
	margin-right 1rem !important
	margin-left 1rem !important

.m-y-1
	margin-top 1rem !important
	margin-bottom 1rem !important

.m-a-2
	margin 1.5rem 1.5rem !important

.m-t-2
	margin-top 1.5rem !important

.m-r-2
	margin-right 1.5rem !important

.m-b-2
	margin-bottom 1.5rem !important

.m-l-2
	margin-left 1.5rem !important

.m-x-2
	margin-right 1.5rem !important
	margin-left 1.5rem !important

.m-y-2
	margin-top 1.5rem !important
	margin-bottom 1.5rem !important

.m-a-3
	margin 3rem 3rem !important

.m-t-3
	margin-top 3rem !important

.m-r-3
	margin-right 3rem !important

.m-b-3
	margin-bottom 3rem !important

.m-l-3
	margin-left 3rem !important

.m-x-3
	margin-right 3rem !important
	margin-left 3rem !important

.m-y-3
	margin-top 3rem !important
	margin-bottom 3rem !important

.p-a-0
	padding 0 0 !important

.p-t-0
	padding-top 0 !important

.p-r-0
	padding-right 0 !important

.p-b-0
	padding-bottom 0 !important

.p-l-0
	padding-left 0 !important

.p-x-0
	padding-right 0 !important
	padding-left 0 !important

.p-y-0
	padding-top 0 !important
	padding-bottom 0 !important

.p-a-1
	padding 1rem 1rem !important

.p-t-1
	padding-top 1rem !important

.p-r-1
	padding-right 1rem !important

.p-b-1
	padding-bottom 1rem !important

.p-l-1
	padding-left 1rem !important

.p-x-1
	padding-right 1rem !important
	padding-left 1rem !important

.p-y-1
	padding-top 1rem !important
	padding-bottom 1rem !important

.p-a-2
	padding 1.5rem 1.5rem !important

.p-t-2
	padding-top 1.5rem !important

.p-r-2
	padding-right 1.5rem !important

.p-b-2
	padding-bottom 1.5rem !important

.p-l-2
	padding-left 1.5rem !important

.p-x-2
	padding-right 1.5rem !important
	padding-left 1.5rem !important

.p-y-2
	padding-top 1.5rem !important
	padding-bottom 1.5rem !important

.p-a-3
	padding 3rem 3rem !important

.p-t-3
	padding-top 3rem !important

.p-r-3
	padding-right 3rem !important

.p-b-3
	padding-bottom 3rem !important

.p-l-3
	padding-left 3rem !important

.p-x-3
	padding-right 3rem !important
	padding-left 3rem !important

.p-y-3
	padding-top 3rem !important
	padding-bottom 3rem !important

.pos-f-t
	position fixed
	top 0
	right 0
	left 0
	z-index 1030
