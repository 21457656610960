.container
    padding-right .9375rem
    padding-left .9375rem
    margin-right auto
    margin-left auto
    box-sizing border-box

    &::after
        display table
        clear both
        content ""

@media (min-width 544px)
    .container
        max-width 576px

@media (min-width 768px)
    .container
        max-width 720px

@media (min-width 992px)
    .container
        max-width 940px

@media (min-width 1200px)
    .container
        max-width 1140px

.container-fluid
    padding-right .9375rem
    padding-left .9375rem
    margin-right auto
    margin-left auto
    max-width 1260px

    &::after
        display table
        clear both
        content ""

.row
    margin-right -.9375rem
    margin-left -.9375rem

    &::after
        display table
        clear both
        content ""

.coll
.col-xs-1
.col-xs-2
.col-xs-3
.col-xs-4
.col-xs-5
.col-xs-6
.col-xs-7
.col-xs-8
.col-xs-9
.col-xs-10
.col-xs-11
.col-xs-12
.col-sm-1
.col-sm-2
.col-sm-3
.col-sm-4
.col-sm-5
.col-sm-6
.col-sm-7
.col-sm-8
.col-sm-9
.col-sm-10
.col-sm-11
.col-sm-12
.col-md-1
.col-md-2
.col-md-3
.col-md-4
.col-md-5
.col-md-6
.col-md-7
.col-md-8
.col-md-9
.col-md-10
.col-md-11
.col-md-12
.col-lg-1
.col-lg-2
.col-lg-3
.col-lg-4
.col-lg-5
.col-lg-6
.col-lg-7
.col-lg-8
.col-lg-9
.col-lg-10
.col-lg-11
.col-lg-12
.col-xl-1
.col-xl-2
.col-xl-3
.col-xl-4
.col-xl-5
.col-xl-6
.col-xl-7
.col-xl-8
.col-xl-9
.col-xl-10
.col-xl-11
.col-xl-12
    position relative
    min-height 1px
    padding-right .9375rem
    padding-left .9375rem
    box-sizing border-box

.col-xs-1
.col-xs-2
.col-xs-3
.col-xs-4
.col-xs-5
.col-xs-6
.col-xs-7
.col-xs-8
.col-xs-9
.col-xs-10
.col-xs-11
.col-xs-12
    float left

.col-xs-1
    width 8.333333%

.col-xs-2
    width 16.666667%

.col-xs-3
    width 25%

.col-xs-4
    width 33.333333%

.col-xs-5
    width 41.666667%

.col-xs-6
    width 50%

.col-xs-7
    width 58.333333%

.col-xs-8
    width 66.666667%

.col-xs-9
    width 75%

.col-xs-10
    width 83.333333%

.col-xs-11
    width 91.666667%

.col-xs-12
    width 100%

.col-xs-pull-0
    right auto

.col-xs-pull-1
    right 8.333333%

.col-xs-pull-2
    right 16.666667%

.col-xs-pull-3
    right 25%

.col-xs-pull-4
    right 33.333333%

.col-xs-pull-5
    right 41.666667%

.col-xs-pull-6
    right 50%

.col-xs-pull-7
    right 58.333333%

.col-xs-pull-8
    right 66.666667%

.col-xs-pull-9
    right 75%

.col-xs-pull-10
    right 83.333333%

.col-xs-pull-11
    right 91.666667%

.col-xs-pull-12
    right 100%

.col-xs-push-0
    left auto

.col-xs-push-1
    left 8.333333%

.col-xs-push-2
    left 16.666667%

.col-xs-push-3
    left 25%

.col-xs-push-4
    left 33.333333%

.col-xs-push-5
    left 41.666667%

.col-xs-push-6
    left 50%

.col-xs-push-7
    left 58.333333%

.col-xs-push-8
    left 66.666667%

.col-xs-push-9
    left 75%

.col-xs-push-10
    left 83.333333%

.col-xs-push-11
    left 91.666667%

.col-xs-push-12
    left 100%

.col-xs-offset-0
    margin-left 0

.col-xs-offset-1
    margin-left 8.333333%

.col-xs-offset-2
    margin-left 16.666667%

.col-xs-offset-3
    margin-left 25%

.col-xs-offset-4
    margin-left 33.333333%

.col-xs-offset-5
    margin-left 41.666667%

.col-xs-offset-6
    margin-left 50%

.col-xs-offset-7
    margin-left 58.333333%

.col-xs-offset-8
    margin-left 66.666667%

.col-xs-offset-9
    margin-left 75%

.col-xs-offset-10
    margin-left 83.333333%

.col-xs-offset-11
    margin-left 91.666667%

.col-xs-offset-12
    margin-left 100%

@media (min-width 544px)
    .col-sm-1
    .col-sm-2
    .col-sm-3
    .col-sm-4
    .col-sm-5
    .col-sm-6
    .col-sm-7
    .col-sm-8
    .col-sm-9
    .col-sm-10
    .col-sm-11
    .col-sm-12
        float left

    .col-sm-1
        width 8.333333%

    .col-sm-2
        width 16.666667%

    .col-sm-3
        width 25%

    .col-sm-4
        width 33.333333%

    .col-sm-5
        width 41.666667%

    .col-sm-6
        width 50%

    .col-sm-7
        width 58.333333%

    .col-sm-8
        width 66.666667%

    .col-sm-9
        width 75%

    .col-sm-10
        width 83.333333%

    .col-sm-11
        width 91.666667%

    .col-sm-12
        width 100%

    .col-sm-pull-0
        right auto

    .col-sm-pull-1
        right 8.333333%

    .col-sm-pull-2
        right 16.666667%

    .col-sm-pull-3
        right 25%

    .col-sm-pull-4
        right 33.333333%

    .col-sm-pull-5
        right 41.666667%

    .col-sm-pull-6
        right 50%

    .col-sm-pull-7
        right 58.333333%

    .col-sm-pull-8
        right 66.666667%

    .col-sm-pull-9
        right 75%

    .col-sm-pull-10
        right 83.333333%

    .col-sm-pull-11
        right 91.666667%

    .col-sm-pull-12
        right 100%

    .col-sm-push-0
        left auto

    .col-sm-push-1
        left 8.333333%

    .col-sm-push-2
        left 16.666667%

    .col-sm-push-3
        left 25%

    .col-sm-push-4
        left 33.333333%

    .col-sm-push-5
        left 41.666667%

    .col-sm-push-6
        left 50%

    .col-sm-push-7
        left 58.333333%

    .col-sm-push-8
        left 66.666667%

    .col-sm-push-9
        left 75%

    .col-sm-push-10
        left 83.333333%

    .col-sm-push-11
        left 91.666667%

    .col-sm-push-12
        left 100%

    .col-sm-offset-0
        margin-left 0

    .col-sm-offset-1
        margin-left 8.333333%

    .col-sm-offset-2
        margin-left 16.666667%

    .col-sm-offset-3
        margin-left 25%

    .col-sm-offset-4
        margin-left 33.333333%

    .col-sm-offset-5
        margin-left 41.666667%

    .col-sm-offset-6
        margin-left 50%

    .col-sm-offset-7
        margin-left 58.333333%

    .col-sm-offset-8
        margin-left 66.666667%

    .col-sm-offset-9
        margin-left 75%

    .col-sm-offset-10
        margin-left 83.333333%

    .col-sm-offset-11
        margin-left 91.666667%

    .col-sm-offset-12
        margin-left 100%

@media (min-width 768px)
    .col-md-1
    .col-md-2
    .col-md-3
    .col-md-4
    .col-md-5
    .col-md-6
    .col-md-7
    .col-md-8
    .col-md-9
    .col-md-10
    .col-md-11
    .col-md-12
        float left

    .col-md-1
        width 8.333333%

    .col-md-2
        width 16.666667%

    .col-md-3
        width 25%

    .col-md-4
        width 33.333333%

    .col-md-5
        width 41.666667%

    .col-md-6
        width 50%

    .col-md-7
        width 58.333333%

    .col-md-8
        width 66.666667%

    .col-md-9
        width 75%

    .col-md-10
        width 83.333333%

    .col-md-11
        width 91.666667%

    .col-md-12
        width 100%

    .col-md-pull-0
        right auto

    .col-md-pull-1
        right 8.333333%

    .col-md-pull-2
        right 16.666667%

    .col-md-pull-3
        right 25%

    .col-md-pull-4
        right 33.333333%

    .col-md-pull-5
        right 41.666667%

    .col-md-pull-6
        right 50%

    .col-md-pull-7
        right 58.333333%

    .col-md-pull-8
        right 66.666667%

    .col-md-pull-9
        right 75%

    .col-md-pull-10
        right 83.333333%

    .col-md-pull-11
        right 91.666667%

    .col-md-pull-12
        right 100%

    .col-md-push-0
        left auto

    .col-md-push-1
        left 8.333333%

    .col-md-push-2
        left 16.666667%

    .col-md-push-3
        left 25%

    .col-md-push-4
        left 33.333333%

    .col-md-push-5
        left 41.666667%

    .col-md-push-6
        left 50%

    .col-md-push-7
        left 58.333333%

    .col-md-push-8
        left 66.666667%

    .col-md-push-9
        left 75%

    .col-md-push-10
        left 83.333333%

    .col-md-push-11
        left 91.666667%

    .col-md-push-12
        left 100%

    .col-md-offset-0
        margin-left 0

    .col-md-offset-1
        margin-left 8.333333%

    .col-md-offset-2
        margin-left 16.666667%

    .col-md-offset-3
        margin-left 25%

    .col-md-offset-4
        margin-left 33.333333%

    .col-md-offset-5
        margin-left 41.666667%

    .col-md-offset-6
        margin-left 50%

    .col-md-offset-7
        margin-left 58.333333%

    .col-md-offset-8
        margin-left 66.666667%

    .col-md-offset-9
        margin-left 75%

    .col-md-offset-10
        margin-left 83.333333%

    .col-md-offset-11
        margin-left 91.666667%

    .col-md-offset-12
        margin-left 100%

@media (min-width 992px)
    .col-lg-1
    .col-lg-2
    .col-lg-3
    .col-lg-4
    .col-lg-5
    .col-lg-6
    .col-lg-7
    .col-lg-8
    .col-lg-9
    .col-lg-10
    .col-lg-11
    .col-lg-12
        float left

    .col-lg-1
        width 8.333333%

    .col-lg-2
        width 16.666667%

    .col-lg-3
        width 25%

    .col-lg-4
        width 33.333333%

    .col-lg-5
        width 41.666667%

    .col-lg-6
        width 50%

    .col-lg-7
        width 58.333333%

    .col-lg-8
        width 66.666667%

    .col-lg-9
        width 75%

    .col-lg-10
        width 83.333333%

    .col-lg-11
        width 91.666667%

    .col-lg-12
        width 100%

    .col-lg-pull-0
        right auto

    .col-lg-pull-1
        right 8.333333%

    .col-lg-pull-2
        right 16.666667%

    .col-lg-pull-3
        right 25%

    .col-lg-pull-4
        right 33.333333%

    .col-lg-pull-5
        right 41.666667%

    .col-lg-pull-6
        right 50%

    .col-lg-pull-7
        right 58.333333%

    .col-lg-pull-8
        right 66.666667%

    .col-lg-pull-9
        right 75%

    .col-lg-pull-10
        right 83.333333%

    .col-lg-pull-11
        right 91.666667%

    .col-lg-pull-12
        right 100%

    .col-lg-push-0
        left auto

    .col-lg-push-1
        left 8.333333%

    .col-lg-push-2
        left 16.666667%

    .col-lg-push-3
        left 25%

    .col-lg-push-4
        left 33.333333%

    .col-lg-push-5
        left 41.666667%

    .col-lg-push-6
        left 50%

    .col-lg-push-7
        left 58.333333%

    .col-lg-push-8
        left 66.666667%

    .col-lg-push-9
        left 75%

    .col-lg-push-10
        left 83.333333%

    .col-lg-push-11
        left 91.666667%

    .col-lg-push-12
        left 100%

    .col-lg-offset-0
        margin-left 0

    .col-lg-offset-1
        margin-left 8.333333%

    .col-lg-offset-2
        margin-left 16.666667%

    .col-lg-offset-3
        margin-left 25%

    .col-lg-offset-4
        margin-left 33.333333%

    .col-lg-offset-5
        margin-left 41.666667%

    .col-lg-offset-6
        margin-left 50%

    .col-lg-offset-7
        margin-left 58.333333%

    .col-lg-offset-8
        margin-left 66.666667%

    .col-lg-offset-9
        margin-left 75%

    .col-lg-offset-10
        margin-left 83.333333%

    .col-lg-offset-11
        margin-left 91.666667%

    .col-lg-offset-12
        margin-left 100%

@media (min-width 1200px)
    .col-xl-1
    .col-xl-2
    .col-xl-3
    .col-xl-4
    .col-xl-5
    .col-xl-6
    .col-xl-7
    .col-xl-8
    .col-xl-9
    .col-xl-10
    .col-xl-11
    .col-xl-12
        float left

    .col-xl-1
        width 8.333333%

    .col-xl-2
        width 16.666667%

    .col-xl-3
        width 25%

    .col-xl-4
        width 33.333333%

    .col-xl-5
        width 41.666667%

    .col-xl-6
        width 50%

    .col-xl-7
        width 58.333333%

    .col-xl-8
        width 66.666667%

    .col-xl-9
        width 75%

    .col-xl-10
        width 83.333333%

    .col-xl-11
        width 91.666667%

    .col-xl-12
        width 100%

    .col-xl-pull-0
        right auto

    .col-xl-pull-1
        right 8.333333%

    .col-xl-pull-2
        right 16.666667%

    .col-xl-pull-3
        right 25%

    .col-xl-pull-4
        right 33.333333%

    .col-xl-pull-5
        right 41.666667%

    .col-xl-pull-6
        right 50%

    .col-xl-pull-7
        right 58.333333%

    .col-xl-pull-8
        right 66.666667%

    .col-xl-pull-9
        right 75%

    .col-xl-pull-10
        right 83.333333%

    .col-xl-pull-11
        right 91.666667%

    .col-xl-pull-12
        right 100%

    .col-xl-push-0
        left auto

    .col-xl-push-1
        left 8.333333%

    .col-xl-push-2
        left 16.666667%

    .col-xl-push-3
        left 25%

    .col-xl-push-4
        left 33.333333%

    .col-xl-push-5
        left 41.666667%

    .col-xl-push-6
        left 50%

    .col-xl-push-7
        left 58.333333%

    .col-xl-push-8
        left 66.666667%

    .col-xl-push-9
        left 75%

    .col-xl-push-10
        left 83.333333%

    .col-xl-push-11
        left 91.666667%

    .col-xl-push-12
        left 100%

    .col-xl-offset-0
        margin-left 0

    .col-xl-offset-1
        margin-left 8.333333%

    .col-xl-offset-2
        margin-left 16.666667%

    .col-xl-offset-3
        margin-left 25%

    .col-xl-offset-4
        margin-left 33.333333%

    .col-xl-offset-5
        margin-left 41.666667%

    .col-xl-offset-6
        margin-left 50%

    .col-xl-offset-7
        margin-left 58.333333%

    .col-xl-offset-8
        margin-left 66.666667%

    .col-xl-offset-9
        margin-left 75%

    .col-xl-offset-10
        margin-left 83.333333%

    .col-xl-offset-11
        margin-left 91.666667%

    .col-xl-offset-12
        margin-left 100%
